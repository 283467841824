import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { QueryParams } from '../../models/query-params';
import { UrlParams } from '../../models/url-params';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private readonly httpClient: HttpClient) {}

    /* Method for calling get multiple param API's by using httpClient */
    get<T>(method: string | undefined, urlParams?: UrlParams, queryParams?: QueryParams, headers?: HttpHeaders) {
        if (urlParams) {
            method = this.parseUrlParams(method ?? '', urlParams);
        }

        // Setup log namespace query parameter
        let params: HttpParams = new HttpParams();
        if (queryParams) {
            for (const queryParam of queryParams) {
                params = params.set(queryParam.key, queryParam.value);
            }
        }

        return this.httpClient.get<T>(environment.url + method, { params: params, headers: headers }).pipe(
            map((res: T) => {
                return res;
            })
        );
    }

    /* Method for calling post multiple param API's by using httpClient */
    post<T>(method: string | undefined, body?: object, urlParams?: UrlParams, queryParams?: QueryParams, headers?: HttpHeaders) {
        if (urlParams) {
            method = this.parseUrlParams(method, urlParams);
        }

        // Setup log namespace query parameter
        let params: HttpParams = new HttpParams();
        if (queryParams) {
            for (const queryParam of queryParams) {
                params = params.set(queryParam.key, queryParam.value);
            }
        }
        return this.httpClient
            .post<T>(environment.url + method, body, {
                params: params,
                headers: headers,
            })
            .pipe(
                map((res: T) => {
                    return res;
                })
            );
    }

    /* Method for calling put multiple param API's by using httpClient */
    put<T>(method: string | undefined, body?: object, urlParams?: UrlParams, queryParams?: QueryParams, headers?: HttpHeaders) {
        if (urlParams) {
            method = this.parseUrlParams(method, urlParams);
        }

        // Setup log namespace query parameter
        let params: HttpParams = new HttpParams();
        if (queryParams) {
            for (const queryParam of queryParams) {
                params = params.set(queryParam.key, queryParam.value);
            }
        }

        return this.httpClient
            .put<T>(environment.url + method, body, {
                params: params,
                headers: headers,
            })
            .pipe(
                map((res: T) => {
                    return res;
                })
            );
    }

    /* Method for calling put multiple param API's by using httpClient */
    patch<T>(method: string | undefined, body?: object, urlParams?: UrlParams, queryParams?: QueryParams, headers?: HttpHeaders) {
        if (urlParams) {
            method = this.parseUrlParams(method, urlParams);
        }

        // Setup log namespace query parameter
        let params: HttpParams = new HttpParams();
        if (queryParams) {
            for (const queryParam of queryParams) {
                params = params.set(queryParam.key, queryParam.value);
            }
        }

        return this.httpClient
            .patch<T>(environment.url + method, body, {
                params: params,
                headers: headers,
            })
            .pipe(
                map((res: T) => {
                    return res;
                })
            );
    }

    /* Method for calling delete multiple param API's by using httpClient */
    delete<T>(method: string | undefined, urlParams?: UrlParams, queryParams?: QueryParams, headers?: HttpHeaders) {
        if (urlParams) {
            method = this.parseUrlParams(method, urlParams);
        }

        // Setup log namespace query parameter
        let params: HttpParams = new HttpParams();
        if (queryParams) {
            for (const queryParam of queryParams) {
                params = params.set(queryParam.key, queryParam.value);
            }
        }

        return this.httpClient.delete<T>(environment.url + method, { params: params, headers: headers }).pipe(
            map((res: T) => {
                return res;
            })
        );
    }

    parseUrlParams(method: string | undefined, urlParams?: UrlParams) {
        if (urlParams && method) {
            for (const urlParam of urlParams) {
                method = method.replace(':' + urlParam.key, urlParam.value);
            }
        }
        return method;
    }
}
