export interface Response {
    categoryList?: unknown;
    responseMessage: string;
    message: string;
    status: Status;
    code: string;
    messageDescription: string | null;
    response: object;
}

export enum Status {
    OK = 'OK',
    NOK = 'NOK',
}

export enum Code {
    OK = 'OK',
    NOT_FOUND = 'NOT_FOUND',
    BAD_REQUEST = 'BAD_REQUEST',
}
