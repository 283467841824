export const apiRoutes = {
  partnerProgramLogin                     : 'api/auth/login',
  partnerProgramForgotPassword            : 'api/auth/forgot-password',
  partnerProgramRegister                  : 'api/auth/register',
  setPassword                             : 'api/auth/set-password',

  checkLoginStatus                        : 'api/remote/logged/status',
  sendOtp                                 : 'api/auth/send-otp',
  verifyOtp                               : 'api/auth/verify-otp',
  acceptTermsAndConditions                : 'api/programs/partner/terms-acceptance',

  linkNewBankAccounts                     : 'api/programs/partner/connect',
  linkNewBankAccountsFull                 : 'api/programs/open-banking/finicity/connect/full',
  getMoovetPayAchConnectLink              : 'api/pay-by-bank/:sessionId/connect',
  finicityPostCallback                    : 'api/programs/partner/callback',

  getBankData                             : 'api/programs/partner/account-data',
  transactionList                         : 'api/programs/partner/account/:id/transactions',

  partnerProgramAccountDataOverviewDetails: 'api/programs/partner/survey',


  adminLogin                              : 'api/auth/adm/login',
  getExternalApiList                      : 'api/projects/mc/dashboard',
  getSystemApiLogDetail                   : 'api/logs/moovetshop/sys/api/get/:id/detail',
  getGraphLogData                         : 'api/logs/get/graph',
  getExternalApiLogDetail                 : 'api/logs/get/:id/detail',
  getFilterExternalLogToExport            : 'api/logs/get/all/filtered',
  getExternalApiLogsPaginated             : 'api/logs/get/all/pagination',
  getSystemApiLogsPaginated               : 'api/logs/moovetshop/sys/api/get/all/pagination',
  getUserResponseApiLogsPaginated         : 'api/reports/survey',
  getUserResponseApiLogsDetails           : 'api/reports/survey/:id',
  getNotificationLogsList                 : 'api/logs/notifications/get/all/pagination',
  getNotificationLogsDetails              : 'api/logs/notifications/get/:id/detail',
  exportUserResponseApiLogs               : 'api/reports/survey/export',
  deleteUserResponseApiLog                : 'api/reports/survey/:id',


  //  Merchant Routes
  portalUsersList                         : 'api/users/all',
  getRegisteredPortalUserDetail           : 'api/users/:id',
  toggleSuperAdminRole                    : 'api/users/toggle/sadm/:userId',
  toggleAdminRole                         : 'api/users/toggle/adm/:userId',
  togglePaymentEnabledStatus              : 'api/users/toggle/pmt/:userId',
  toggleWorkDayEnabledStatus              : 'api/users/toggle/workday/:userId',
  toggleAccountingRoleStatus              : 'api/users/toggle/accounting/:userId',
  togglePosRoleStatus                     : 'api/users/toggle/pos/:userId',

  updateUserPrivilages                    : 'api/users/privilege/:userId/assign',
  fetchUserPrivilages                     : 'api/users/privilege/:userId',

  getBankList                             : 'api/get-all-added-bank-list',
  getUserResponseInsights                 : 'api/user-response/insights'
}
